import { useMatches } from '@remix-run/react'

import type { Theme } from '@/utils/types'

type ThemeMatch = { handle: { theme: Theme } }

export function useTheme() {
  const matches = useMatches()
  const match = matches.find((match) => match.handle && typeof match.handle === 'object' && 'theme' in match.handle)

  if (match) {
    return (match as ThemeMatch).handle.theme
  }

  return 'ascii'
}
