import { type LoaderFunctionArgs, type LinksFunction, json } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError
} from '@remix-run/react'
import { withSentry, captureRemixErrorBoundaryError } from '@sentry/remix'

import inter from '@/assets/fonts/InterVar.var.woff2'
import ascii from '@/assets/styles/ascii.css?url'
import legacy from '@/assets/styles/index.css?url'
import Analytics from '@/components/Analytics'
import SiteHeader from '@/components/SiteHeader'
import StatusCode from '@/components/StatusCode'
import { Current } from '@/current'
import { useNonce } from '@/utils/nonce'
import { useTheme } from '@/utils/theme'

import { getUserIdFromRequest } from './auth'
import { env } from './env.server'

type LoaderData = {
  development: boolean
  env: typeof env
  userSignedIn: boolean
}

export async function loader({ request }: LoaderFunctionArgs) {
  const development = process.env.NODE_ENV === 'development'
  const userId = getUserIdFromRequest(request)

  return json<LoaderData>({
    development: development,
    env: env,
    userSignedIn: !!userId
  })
}

export const links: LinksFunction = () => [
  { rel: 'icon', href: '/favicon.ico', type: 'image/x-icon', sizes: '16x16' },
  { rel: 'icon', href: '/icon.png', type: 'image/png', sizes: '32x32' },
  { rel: 'apple-touch-icon', href: '/apple-touch-icon.png', type: 'image/png', sizes: '32x32' },
  { rel: 'manifest', href: '/manifest.webmanifest' }
]

function Root() {
  const data = useLoaderData<LoaderData>()
  const theme = useTheme()
  const nonce = useNonce()

  const current = {
    development: data.development,
    env: data.env,
    theme: theme,
    userSignedIn: data.userSignedIn
  }

  return (
    <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#111111' />
        <meta name='user-signed-in' content={current.userSignedIn.toString()} />
        <Meta />
        {current.theme === 'ascii' ? (
          <link rel='stylesheet' href={ascii} />
        ) : (
          <>
            <link rel='stylesheet' href={legacy} />
            <link rel='preload' href={inter} as='font' type='font/woff2' crossOrigin='anonymous' />
          </>
        )}
        {current.env['IMAGE_CDN_ENABLED'] && <link rel='preconnect' href={current.env['IMAGE_CDN']} />}
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(current.env)}`
          }}
          nonce={nonce}
        />
      </head>

      <body>
        <Analytics />
        <Current.Provider value={current}>
          <Outlet />
        </Current.Provider>
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: 'PlanetScale, Inc.',
              url: 'https://planetscale.com',
              sameAs: [
                'https://twitter.com/PlanetScale',
                'https://www.facebook.com/planetscaledata/',
                'https://www.instagram.com/planetscale/'
              ],
              address: {
                '@type': 'PostalAddress',
                streetAddress: 'WeWork c/o PlanetScale, 535 Mission Street, 14th Floor',
                addressLocality: 'San Francisco',
                addressRegion: 'CA',
                postalCode: '94105',
                addressCountry: 'US'
              }
            })
          }}
        />
      </body>
    </html>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()

  captureRemixErrorBoundaryError(error)

  const [code, title, heading, description] =
    isRouteErrorResponse(error) && error.status === 404
      ? [
          error.status,
          `${error.status} Page not found - PlanetScale`,
          `Sorry, that page could not be found.`,
          `The requested page either doesn’t exist or you don’t have access to it.`
        ]
      : isRouteErrorResponse(error)
        ? [
            error.status,
            `${error.status} Something went wrong - PlanetScale`,
            `An unexpected error occurred.`,
            `These errors are tracked and we’ll try to get this fixed soon. Please try again later.`
          ]
        : [
            500,
            `500 Something went wrong - PlanetScale`,
            `An unexpected error occurred.`,
            `These errors are tracked and we’ll try to get this fixed soon. Please try again later.`
          ]

  if (code !== 404) {
    console.error(error)
  }

  return (
    <html lang='en'>
      <head>
        <title>{title}</title>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#111111' />
        <Meta />
        <link rel='stylesheet' href={ascii} />
        <Links />
      </head>

      <body>
        <SiteHeader />

        <main className='container my-6 max-w-7xl px-3 sm:px-5 lg:px-12'>
          {code === 404 && <StatusCode code={code} />}
          {code === 500 && <StatusCode code={code} />}

          <h1>{heading}</h1>

          <p>{description}</p>

          <nav>
            <ul className='flex gap-x-1'>
              <li>
                <a href='/docs'>Documentation</a>
              </li>
              <li className='text-decoration'>|</li>
              <li>
                <a href='https://support.planetscale.com/hc/en-us'>Support</a>
              </li>
            </ul>
          </nav>
        </main>
      </body>
    </html>
  )
}

export default withSentry(Root)
