import { useEffect } from 'react'

import { useLocation } from '@remix-run/react'

import { page } from '@/utils/analytics.client'

export default function Analytics() {
  const { pathname } = useLocation()

  useEffect(() => {
    page()
  }, [pathname])

  return null
}
