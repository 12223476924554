type Props = {
  code: 404 | 500
}

export default function StatusCode({ code }: Props) {
  if (code === 404) {
    return (
      <>
        <pre className='inline-block overflow-visible bg-primary p-0 leading-4'>{`
██╗  ██╗ ██████╗ ██╗  ██╗
██║  ██║██╔═████╗██║  ██║
███████║██║██╔██║███████║
╚════██║████╔╝██║╚════██║
     ██║╚██████╔╝     ██║
     ╚═╝ ╚═════╝      ╚═╝
`}</pre>
      </>
    )
  }

  if (code === 500) {
    return (
      <>
        <pre className='inline-block overflow-visible bg-primary p-0 leading-4'>{`
███████╗ ██████╗  ██████╗
██╔════╝██╔═████╗██╔═████╗
███████╗██║██╔██║██║██╔██║
╚════██║████╔╝██║████╔╝██║
███████║╚██████╔╝╚██████╔╝
╚══════╝ ╚═════╝  ╚═════╝
`}</pre>
      </>
    )
  }
}
